import api from './api';

const service = {
  async search(query) {
    return await api.get(`/menu-manage/search/${query.aggregator_id}`);
  },
  async update(params, payload) {
    return await api.put(`/menu-manage/update/${params.aggregator_id}`, payload);
  },
}

export default service;