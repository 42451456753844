import api from './api';

const service = {
    // 需要調達計画 当日/翌日
    async searchSubmissionProcurePlan(params) {
        return await api.get(`/aggregators/${params.aggregator_id}/areas/${params.area_id}/dates/${params.target_date}/demand_power/trading/plan`);
    },
    async updateProcurePlan(params) {
        return await api.post(`/aggregators/${params.aggregator_id}/areas/${params.area_id}/dates/${params.target_date}/demand_power/trading/plan/making`);
    },
    async DownloadProcurePlanXml(params) {
        return await api.get(`/files/xml/aggregators/${params.aggregator_id}/areas/${params.area_id}/dates/${params.target_date}/demand_power/trading/plan`)
    },
    /*
    async DownloadSupplyDemandSalesPlanXml(params) {
        return await api.get(`/files/xml/aggregators/${params.aggregator_id}/areas/${params.area_id}/dates/${params.target_date}/demand_power/supply/trading/plan?demand_flag=${params.demand_flag}`, 
        {
            responseType: 'arraybuffer',
            headers: { Accept: 'application/zip' },
        })
    },
    */
    async updateSupplySubmissionOctto(params) {
        return await api.post(`/aggregators/${params.aggregator_id}/areas/${params.area_id}/dates/${params.target_date}/demand_power/trading/plan/upload`)
    },
    // 需要調達計画 週間
    async searchSubmissionProcurePlanWeek(params) {
        return await api.get(`/aggregators/${params.aggregator_id}/areas/${params.area_id}/dates/${params.target_date}/demand_power/trading/weeks_plan`);
    },
    async updateProcurePlanWeek(params) {
        return await api.post(`/aggregators/${params.aggregator_id}/areas/${params.area_id}/dates/${params.target_date}/demand_power/trading/plan/weeks_making`);
    },
    async DownloadProcurePlanXmlWeek(params) {
        return await api.get(`/files/xml/aggregators/${params.aggregator_id}/areas/${params.area_id}/dates/${params.target_date}/demand_power/trading/weeks_plan`)
    },
    async updateSupplySubmissionOcttoWeek(params) {
        return await api.post(`/aggregators/${params.aggregator_id}/areas/${params.area_id}/dates/${params.target_date}/demand_power/trading/plan/weeks_upload`)
    },
    // 計画受付検索
    async searchDemandReceptionPlan(aggregator_id,params) {
        const baseUrl = `/aggregators/${aggregator_id}/demand_power/trading/plan/acceptance`;
        const paramsUrl = Object.entries(params).map((e) => {
            let key = e[0];
            let value = e[1];
            return `${key}=${value}`;
          }).join("&");
        const url = baseUrl + '?' + paramsUrl;
        return await api.get(url);
    },
    // 自動/手動提出方法
    async searchAutoSubmissionConfig(params) {
        return await api.get(`/aggregators/${params.aggregator_id}/areas/${params.area_id}/demand_power/trading/plan/automatic_submission`)
    },
    async updateAutoSubmissionConfig(condition, payload){
        return await api.put(`/aggregators/${condition.aggregator_id}/areas/${condition.area_id}/demand_power/trading/plan/automatic_submission`, payload );
    },
    // 需要調達計画 翌々日
    async searchSubmissionProcurePlanTwoDaysLater(params) {
        return await api.get(`/aggregators/${params.aggregator_id}/areas/${params.area_id}/dates/${params.target_date}/demand_power/trading/two_days_later_plan`);
    },
    async updateProcurePlanTwoDaysLater(params) {
        return await api.post(`/aggregators/${params.aggregator_id}/areas/${params.area_id}/dates/${params.target_date}/demand_power/trading/plan/two_days_later_making`);
    },
    async DownloadProcurePlanXmlTwoDaysLater(params) {
        return await api.get(`/files/xml/aggregators/${params.aggregator_id}/areas/${params.area_id}/dates/${params.target_date}/demand_power/trading/two_days_later_plan`)
    },
    async updateSupplySubmissionOcttoTwoDaysLater(params) {
        return await api.post(`/aggregators/${params.aggregator_id}/areas/${params.area_id}/dates/${params.target_date}/demand_power/trading/plan/two_days_later_upload`)
    },
}

export default service;
